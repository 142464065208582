import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ModularBlocks from '../components/ModularBlocks';

const IndexPage = () => {
  const {
    datoCmsHome: {
      seoMetaTags,
      title,
      bannerOverline,
      bannerLinkPage,
      bannerLinkText,
      bannerImage,
      bannerUsps,
      modularBlocks,
    },
  } = useStaticQuery(graphql`
    query IndexPageQuery {
      datoCmsHome {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        title
        bannerOverline
        bannerLinkPage {
          ...LinkFragment
        }
        bannerLinkText
        bannerImage {
          ...HomepageBannerImageFragment
        }
        bannerUsps {
          text
        }
        modularBlocks {
          ...FeaturedEquipmentCategoriesModularBlockFragment
          ...ContentModularBlockFragment
          ...DownloadStripModularBlockFragment
          ...FeaturedCranesModularBlockFragment
          ...FeaturedEquipmentCategoriesModularBlockFragment
          ...FeaturedEquipmentModularBlockFragment
          ...FeaturedLogosModularBlockFragment
          ...FeaturedProjectsModularBlockFragment
          ...FeaturedTeamModularBlockFragment
          ...ImageContentModularBlockFragment
          ...LatestNewsModularBlockFragment
          ...LatestPressReleasesModularBlockFragment
          ...PageLinkCardsModularBlockFragment
          ...TestimonialModularBlockFragment
          ...VideoContentModularBlockFragment
        }
      }
    }
  `);

  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <Banner
          overline={bannerOverline}
          heading={title}
          link={{
            page: bannerLinkPage,
            text: bannerLinkText,
          }}
          image={bannerImage}
          usps={bannerUsps}
          isHomepage={true}
        />
        <ModularBlocks items={modularBlocks} />
      </main>
    </Layout>
  );
};

export default IndexPage;
